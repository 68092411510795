import React from 'react'
import './index.css'

function App() {
  return (
    <div className="app">
      <header className="app-header">☀️ Work-in-progress. 🌙</header>
    </div>
  )
}

export default App
